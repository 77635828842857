.item {
  overflow: hidden;
  position: relative;
  .thumbnail {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    @media screen and (min-width: 768px) {
      overflow: hidden;
      transition: 0.5s ease-in-out;
      img {
        transition: 0.5s ease-in-out;
      }
      &:hover {
        transform: scale3d(0.9, 0.9, 0.9);
        img {
          transform: scale3d(1.25, 1.25, 1.25);
        }
      }
    }
  }
  .info {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    div {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      height: 4rem;
      justify-content: center;
      position: relative;
      &:first-of-type {
        flex: 1 1 25%;
        background-color: var(--white);
        z-index: 3;
        h3,
        p {
          color: var(--primary);
          text-transform: uppercase;
          font-family: ITCJohnstonPro;
        }
        h3 {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        p {
          font-size: 0.8rem;
          line-height: 0.8rem;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 100%;
          height: 8rem;
          width: 4rem;
          margin-left: -0.75rem;
          background-color: var(--white);
          transform: rotate(45deg);
          z-index: -1;
        }
      }
      &:last-of-type {
        flex: 1 1 75%;
        text-align: right;
        z-index: 1;
        h3,
        p {
          color: var(--white);
          text-transform: uppercase;
          font-family: ITCJohnstonPro;
          line-height: 1;
        }
        h3 {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        p {
          font-size: 0.8rem;
          line-height: 0.8rem;
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--primary);
          opacity: 0.45;
          z-index: -1;
        }
      }
    }
  }
}
