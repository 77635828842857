.blogListItem {
  display: flex;
  gap: 3rem;
  margin-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--gray);
  width: 100%;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
  .thumbnail {
    flex: 0 0 30%;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  .content {
    flex: 0.7;
    display: flex;
    flex-direction: column;
  }
  .publishingDate {
    font-size: 1.125rem;
    font-weight: 100;
    font-family: BauerBodoniItalic;
    color: var(--gray);
    margin-bottom: 1rem;
  }
  h2 {
    color: var(--primary);
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-family: ITCJohnstonPro;
  }
  .description {
    font-size: 1.125rem;
    font-weight: 100;
    font-family: BauerBodoniItalic;
    color: var(--gray);
    margin-bottom: 1rem;
    line-height: 0.8;
    p {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  .readMoreBtn {
    border: 1px solid var(--primary);
    color: var(--primary);
    background: none;
    text-transform: uppercase;
    padding: 0.75rem 1.25rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: ITCJohnstonPro;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 1px;
    line-height: 1;
    width: fit-content;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    &:before {
      content: "";
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: var(--primary);
      z-index: -1;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
    &:hover {
      color: var(--white);
      &:before {
        right: 0;
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .readMoreBtn {
      margin-left: auto;
      &:before {
        display: none;
      }
      &:hover {
        &:before {
          display: none;
        }
      }
    }
  }
}
