.header {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  width: 100%;
  max-width: 100%;

  &.sticky {
    height: 9rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 110;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .logo {
      color: transparent;
      font-size: 0;
      width: 100%;
      display: block;
      transition: 0.45s ease-in-out;
      &:nth-of-type(2) {
        max-width: 7rem;
        margin-right: auto;
        margin-left: auto;
        justify-self: center;
        align-self: center;
      }
      &:first-of-type {
        max-width: 12rem;
        opacity: 0;
        justify-self: left;
        align-self: center;
      }
    }

    .nav {
      margin-right: -13px;
      justify-self: right;
      align-self: center;
      display: flex;
      align-items: center;

      .lang {
        margin-right: 4rem;
        display: flex;
        padding-top: 0.5rem;
        justify-content: center;
        align-items: center;
        position: relative;
        align-self: center;
        @media screen and (max-width: 767px) {
          display: none;
        }
        button {
          background-color: transparent;
          border: none;
          color: var(--primary);
          font-family: ITCJohnstonPro;
          position: relative;
          font-size: 1rem;
          line-height: 1;
          cursor: pointer;
          font-weight: 100;
          opacity: 0.8;
          &.active {
            font-weight: 400;
            opacity: 1;
          }
        }
        span {
          margin: -0.125rem 0.5rem 0;
          font-size: 0.8rem;
          cursor: default;
          pointer-events: none;
          color: var(--primary);
          font-family: ITCJohnstonPro;
          position: relative;
        }
      }
      .hamburgerIcon {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        span {
          cursor: pointer;
          color: var(--primary);
          text-transform: uppercase;
          font-family: ITCJohnstonPro;
          font-size: 1rem;
          padding-top: 0.5rem;
          margin-right: 0.5rem;
          transition: all 200ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        > div {
          position: relative;
          height: 60px;
          width: 60px;
          .icon {
            position: absolute;
            left: 25%;
            top: 50%;
            width: 32px;
            height: 3px;
            background-color: var(--primary);
            transition: all 200ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
            &:nth-of-type(1) {
              transform: translateY(-8px);
              animation-delay: 100ms;
            }
            &:nth-of-type(3) {
              transform: translateY(8px);
              animation-delay: 250ms;
            }
          }
        }
      }
      &.active {
        .hamburgerIcon {
          span {
            color: var(--white);
          }
          > div {
            .icon {
              background-color: var(--white);
              &:nth-of-type(1) {
                transform: rotate(40deg);
              }
              &:nth-of-type(2) {
                transform: rotate(-40deg);
              }
              &:nth-of-type(3) {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
  .logoDisabled {
    pointer-events: none;
    cursor: default;
  }
  .logo img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &.fixed {
    height: 9rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    @media screen and (max-width: 767px) {
      .wrapper {
        justify-content: flex-end;
      }
      .logo {
        left: 1rem;
        transform: none;
      }
      nav {
        margin: 0;
      }
    }
  }
  &.transformed {
    background-color: hsla(270, 50%, 40%, 0.65);
    justify-content: space-between;
    height: 7rem;
    @media screen and (max-width: 767px) {
      height: 3rem;
    }
    .nav {
      .lang {
        button,
        span {
          color: var(--white);
        }
      }
      .hamburgerIcon {
        span {
          color: var(--white);
        }
        div {
          .icon {
            background-color: var(--white);
          }
        }
      }
    }
    .logo {
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:first-of-type {
        opacity: 1;
        max-width: 15rem;
      }
      @media screen and (max-width: 767px) {
        max-width: 8rem;
      }
    }
    &.withMenu {
      background-color: transparent;
      .logo {
        max-width: 15rem;
        top: 1rem;
        @media screen and (max-width: 767px) {
          left: 1rem;
          transform: none;
          max-width: 8rem;
          top: auto;
        }
      }
    }
  }
  &.withMenu {
    .logo {
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:first-of-type {
        opacity: 1;
        max-width: 15rem;
      }
      @media screen and (max-width: 767px) {
        max-width: 8rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-color: hsla(270, 50%, 40%, 0.65);
    justify-content: space-between;
    height: 3rem;
    &.fixed {
      height: 3rem;
      .wrapper {
        justify-content: flex-end;
      }
      .logo {
        left: 1rem;
        transform: none;
      }
      nav {
        margin: 0;
      }
      .nav {
        .lang {
          button,
          span {
            color: var(--white);
          }
        }
        .hamburgerIcon {
          span {
            color: var(--white);
          }
          div {
            .icon {
              background-color: var(--white);
            }
          }
        }
      }
      .logo {
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:first-of-type {
          opacity: 1;
          max-width: 15rem;
        }
        max-width: 8rem;
      }
      &.withMenu {
        background-color: transparent;
        .logo {
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:first-of-type {
            opacity: 1;
            max-width: 15rem;
          }
          max-width: 8rem;
        }
      }
    }
  }
}

.menu {
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--primary);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transition: all 200ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
  &.visible {
    opacity: 1;
    z-index: 50;
    transition: all 200ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    li {
      a {
        color: var(--white);
        text-transform: uppercase;
        font-family: ITCJohnstonPro;
        font-size: 2.25rem;
        margin-bottom: 2rem;
        display: block;
        position: relative;
        padding-bottom: 0.5rem;
        overflow: hidden;
        line-height: 1;
        @media screen and (max-width: 767px) {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }
        &:before {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: -100%;
          height: 3px;
          background-color: var(--white);
          transition: all 350ms ease-in-out;
        }
        &:hover {
          &:before {
            left: 0;
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    width: 0%;
    // transform: scale(.3);
    opacity: 0;
  }

  100% {
    width: 50%;
    // transform:scale(1);
    opacity: 1;
  }
}
